import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';

const WWGuidesEchoFarm: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page wuthering'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Echo farming routes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_echo.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Echo farming routes</h1>
          <h2>
            Find out the best and most optimal farming routes for Echoes in
            Wuthering Waves!
          </h2>
          <p>
            Last updated: <strong>20/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Wuthering Waves Database is currently being created!</h4>
          <p>The Echo farming routes will be added soon!</p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesEchoFarm;

export const Head: React.FC = () => (
  <Seo
    title="Echo farming routes | Wuthering Waves | Prydwen Institute"
    description="Find out the best and most optimal farming routes for Echoes in Wuthering Waves!"
    game="ww"
  />
);
